<template>
  <section>
    <b-row class="mt-2">
      <b-col xl="12" md="12">
        <ag-grid-table
          ref="grid-historico-precios"
          :configUrl="historicoPreciosConfig"
          :dataUrl="historicoPreciosData"
        >
        </ag-grid-table>
      </b-col>
    </b-row>
  </section>
</template>
<script>
import
{
  BTabs, 
  BTab,
  BRow,
  BCol

} from "bootstrap-vue";

import AgGridTable from '@/views/brunacomponents/ag-grid-table/AgGridTable.vue'

import useApiServices from  '@/services/useApiServices.js';


export default {
  data()
  {
    return {


      historicoPreciosConfig: useApiServices.historicoPreciosConfig,
      historicoPreciosData: useApiServices.historicoPreciosData,





    };
  },
  components: {
    BTabs,
    BTab,
    BRow,
    BCol,
    AgGridTable,
  },

  directives: {
  },

  mounted()
  {

  },

  methods: {

    refreshRows()
    {
      this.$refs['grid-historico-precios'].refreshRows()
    }


  },
};
</script>

<style scoped>
.carga-data {
  margin-top: 4%;
}
</style>

