<template>
  <section>
    <BrunaForm
      ref="priceRef"
      :fields="this.price.fields"
      :card="this.price.card"
      @change="priceChange"

    ></BrunaForm>

    <b-card>
      <b-card-body>
        <b-button
          variant="primary"
          class="mt-4 float-right"
          @click="save"
          size="lg"
          >Guardar</b-button
        >
      </b-card-body>
    </b-card>
  </section>
</template>

<script>



import BrunaForm from "@/views/brunacomponents/BrunaForm.vue";
import BrunaFormRepeat from "@/views/brunacomponents/BrunaFormRepeat.vue";

import useApiServices from '@/services/useApiServices.js';

import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";

import
{
  BCard,
  BCardBody,
  BCardTitle,
  BCardSubTitle,
  BModal,
  BCardText,
  VBTooltip,
  VBPopover,
  BButton
} from "bootstrap-vue";


export default {

  directives: {
    'b-tooltip': VBTooltip,
    "b-popover": VBPopover,
  },

  data()
  {

    let week_options = [];
    let inputs_price_calibres_brasil = [];

    for (let i = 1; i <= 52; i++)
    {
      week_options.push({ value: i, text: "Semana " + i });
    }

    let prices_calibres_brasil = [
      { id: 'price_s_6_8', label: 'Price S 6-8' },
      { id: 'price_s_8_10', label: 'Price S 8-10' },
      { id: 'price_s_10_12', label: 'Price S 10-12' },
      { id: 'price_s_12_14', label: 'Price S 12-14' },
      { id: 'price_s_14_16', label: 'Price S 14-16' },
      { id: 'price_s_16_18', label: 'Price S 16-18' },
    ]


    prices_calibres_brasil.forEach(item =>
    {

      inputs_price_calibres_brasil.push({
        label: item.label,
        type: "input",
        id: item.id,
        rules: 'required|numeric2',
        format: {
          xs: 4,
          sm: 4,
          md: 4,
          lg: 4,
          xl: 4
        },
        value: null
      })


    })



    return {



      price: {
        card: {
          title: "Precios Mercado Brasil",
          subtitle: "",
          tooltip: ""
        },
        fields: [

          {
            label: "Date",
            type: "datepicker",
            id: 'date',
            rules: 'required',
            format: {
              xs: 4,
              sm: 4,
              md: 4,
              lg: 4,
              xl: 4
            },
            value: null
          },


          {
            label: "Week",
            type: "select",
            id: 'week',
            rules: 'required',
            options: [
              { value: null, text: "Selecciona una semana" },
              ...week_options

            ],
            format: {
              xs: 4,
              sm: 4,
              md: 4,
              lg: 4,
              xl: 4
            },
            value: null

          },


          ...inputs_price_calibres_brasil


        ]
      },



    };
  },
  components: {
    BCard,
    BCardBody,
    BCardTitle,
    BCardSubTitle,
    BButton,
    VBPopover,
    VBTooltip,
    BrunaForm,
    BrunaFormRepeat,
    BModal,
    BCardText,
  },
  methods: {

    save()
    {

      let data = this.price.fields.reduce((acumulador, field) => { acumulador[field.id] = field.value; return acumulador }, {})

      console.log({data})      


      let self = this;

      Promise.all([
        this.$refs.priceRef.validate(),


      ]).then(([priceValid]) =>
      {


        if (priceValid)
        {

          this.$bvModal
            .msgBoxConfirm('El ingreso de precios desencadena una nueva predicción que afecta los valores de las próximas planificaciones. ¿Desea continuar?', {
              title: 'Aviso Importante',
              centered: true,
              okTitle: 'Si',
              cancelTitle: 'No',
            })
            .then(value =>
            {

              if (value)
              {
                useApiServices.postPreciosBrasilCreate(data, this)
                  .then((response) =>
                  {
                    console.log(response);

                    this.$toast({
                      component: ToastificationContent,
                      position: "top-right",
                      props: {
                        title: `Carga Exitosa`,
                        icon: "UploadIcon",
                        variant: "success",
                        html: `Se ha guardado el nuevo registro exitosamente.`,
                      },
                    });

                  })
                  .catch((error) =>
                  {
                    this.$toast({
                      component: ToastificationContent,
                      position: "top-right",
                      props: {
                        title: `Ocurrio un Error`,
                        icon: "UploadIcon",
                        variant: "danger",
                        html: `Ha ocurrido un error al crear el registro.`,
                      },
                    });

                  });

              }

            })




        }


      })


    },

    priceChange(data)
    {
      //this.form_values.price = data;
    },





  },
};
</script>

<style scoped>
.titulo {
  margin-top: 5%;
}
</style>

<style lang="scss">
.card {
  .card-title {
    margin-bottom: 1.5rem !important;
  }

  ::v-deep .card-header {
    .heading-elements {
      position: static;
      cursor: inherit;

      .list-inline {
        display: block;

        li {
          a {
            padding: 0;
          }

          &:not(:last-child) {
            margin-right: 1rem;
          }
        }
      }
    }
  }
}
</style>

<style lang="scss">
@import "@core/scss/vue/libs/vue-autosuggest.scss";
</style>