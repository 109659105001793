<template>
  <b-card>
    <div class="card-header">
      <!-- Title & SubTitle -->
      <div>
        <b-card-title>{{ card.title }}</b-card-title>
        <b-card-sub-title>{{ card.subtitle }}</b-card-sub-title>
      </div>

      <!-- Card Actions -->
      <div class="heading-elements">
        <ul class="list-inline mb-0">
          <li>
            <feather-icon
              icon="HelpCircleIcon"
              v-b-tooltip.hover.left="card.tooltip"
              size="20"
            />
          </li>
        </ul>
      </div>
    </div>

    <b-card-body>
      <validation-observer ref="simpleRules">
        <b-row v-for="(item, itemIndex) in items" :key="itemIndex" >
          <b-col
            v-for="(field, index) in item.fields"
            :key="index"
            :xs="field.format.xs"
            :sm="field.format.sm"
            :md="field.format.md"
            :lg="field.format.lg"
            :xl="field.format.xl"
            v-if="(field.show === undefined) ? true: field.show"
          >
            <validation-provider
              #default="{ errors }"
              :name="field.label"
              :rules="field.rules"
              
            >
              <b-form-group :label="field.label" :label-for="field.id">
                <span
                  class="d-inline-block"
                  style="width:100%"
                  tabindex="0" 
                  v-b-tooltip.top
                  :title="field.tooltip"
                >
                  <b-form-input
                    v-if="field.type == 'input'"
                    :id="field.id"
                    :disabled="field.disabled"
                    :state="errors.length > 0 ? false : null"
                    :title="field.tooltip"
                    :value="formatInputValue(field.value)"
                    @input="(value) => formInputChange(item, value, field)"
                  ></b-form-input>

                  <b-form-select
                    v-if="field.type == 'select'"
                    :id="field.id"
                    :options="field.options"
                    :disabled="field.disabled"
                    :state="errors.length > 0 ? false : null"
                    :value="field.value"
                    @input="(value) => formSelectChange(item, value, field)"
                  ></b-form-select>

                  <b-form-datepicker
                    v-if="field.type == 'datepicker'"
                    :id="field.id"
                    placeholder="Seleccione fecha"
                    :disabled="field.disabled"
                    :state="errors.length > 0 ? false : null"
                    :value="field.value"
                    @input="(value) => formDatepickerChange(item, value, field)"
                  ></b-form-datepicker>

                  <small class="text-danger">{{ errors[0] }}</small>
                </span>
              </b-form-group>
            </validation-provider>
          </b-col>

          <b-col v-if="itemIndex > 0" class="verticalCenter">
            <b-button
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              variant="danger"
              size="sm"
              class="btn-icon rounded-circle"
              @click="() => removeItem(item.id)"
            >
              <feather-icon icon="Trash2Icon" />
            </b-button>
          </b-col>

          <b-col cols="12">
            <hr />
          </b-col>
        </b-row>
        <b-button
          v-ripple.400="'rgba(255, 255, 255, 0.15)'"
          variant="outline-success"
          size="sm"
          class="btn-icon rounded-circle"
          @click="addItem"
        >
          <feather-icon icon="PlusIcon" />
        </b-button>
      </validation-observer>
    </b-card-body>
  </b-card>
</template>

<script>
const axios = require("axios");

import Vue from 'vue'

import { ValidationProvider, ValidationObserver, localize } from 'vee-validate'
import
{
  required, email, confirmed, url, between, alpha, integer, password, min, digits, alphaDash, length, numeric, decimal
} from '@validations'
import Ripple from 'vue-ripple-directive'

import
{
  BRow,
  BCol,
  BFormSelect,
  BDropdown,
  BDropdownItem,
  BButton,
  BCard,
  BCardBody,
  BForm,
  BFormGroup,
  BFormInput,
  BFormCheckbox,
  BFormDatepicker,
  BCardTitle,
  BCardSubTitle,
  VBTooltip,
  VBPopover
} from "bootstrap-vue";

export default {

  components: {
    BRow,
    BCol,
    BFormSelect,
    BDropdown,
    BDropdownItem,
    BButton,
    BCard,
    BCardBody,
    BForm,
    BFormGroup,
    BFormInput,
    BFormCheckbox,
    BFormDatepicker,
    BCardTitle,
    BCardSubTitle,
    ValidationProvider,
    ValidationObserver,
    VBTooltip,
    VBPopover

  },
  directives: {
    'b-tooltip': VBTooltip,
    "b-popover": VBPopover,
    Ripple,
  },

  created()
  {
    localize('es')
  },

  methods: {

 
  formatInputValue(value){

      try{


        if(isNaN(value)){
          return value;
        }        

        let num = parseFloat(value)

        if(isNaN(num)){
          return value;
        }

        return Math.round(num * 100)/100;


      }catch(error){

        return value;

      }
    },


    addItem()
    {
      let clonedFields = JSON.parse(JSON.stringify(this.items[0].fields))

      clonedFields.forEach(clonedField =>
      {
        clonedField.value = null;
      });

      this.nextTodoId += 1

      this.items.push({
        id: this.nextTodoId,
        fields: clonedFields
      })


    },

    removeItem(itemId)
    {
      this.items = this.items.filter(item => item.id != itemId);


    },

    formInputChange(item, value, field)
    {
      field.value = value;

      this.$emit('change', item, value, field);
    },

    formDatepickerChange(item, value, field)
    {
      field.value = value;

      this.$emit('change', item, value, field);
    },

    formSelectChange(item, value, field)
    {
      field.value = value;

      this.$emit('change', item, value, field);

    },

    validate()
    {
      return this.$refs.simpleRules.validate()
    }

  },

  computed: {

  },

  mounted()
  {

  },

  beforeDestroy()
  {
    //console.log("beforeDestroy 2");
  },

  data()
  {
    return {

      locale: 'es',


      nextTodoId: 1,

    };
  },

  props: {

    card: {
      type: Object,
      default: () => ({
        title: "Titulo",
        subtitle: "Subtitulo"
      })
    },

    items: {
      type: Array,
      default: () => [

      ]

    },
  },
};
</script>

<style lang="scss" scoped>
.verticalCenter {
  align-self: center;
}
</style>