<template>
  <section>
    <b-tabs @activate-tab="onTabChange">
      <b-tab title="Precios Brasil">
        <IngresoPreciosBrasil></IngresoPreciosBrasil>
      </b-tab>

      <b-tab title="Precios USA">
        <IngresoPreciosUSA></IngresoPreciosUSA>
      </b-tab>

      <b-tab title="Precios Noruega">
        <IngresoPreciosNoruega></IngresoPreciosNoruega>
      </b-tab>

      <b-tab title="Histórico Precios">
        <HistoricoPrecios ref="historico-precios"></HistoricoPrecios>
      </b-tab>
    </b-tabs>
  </section>
</template>
<script>
import
{
  BTabs,
  BTab

} from "bootstrap-vue";


import IngresoPreciosBrasil from "./IngresoPreciosBrasil.vue";
import IngresoPreciosUSA from "./IngresoPreciosUSA.vue";
import IngresoPreciosNoruega from "./IngresoPreciosNoruega.vue";
import HistoricoPrecios from "./HistoricoPrecios.vue";

export default {
  data()
  {
    return {






    };
  },
  components: {
    BTabs,
    BTab,
    IngresoPreciosBrasil,
    IngresoPreciosUSA,
    IngresoPreciosNoruega,
    HistoricoPrecios
  },

  directives: {
  },

  mounted()
  {

  },

  methods: {

    onTabChange(newTabIndex)
    {
      //console.log({newTabIndex})
      if(newTabIndex == 3)
      {
        this.$refs['historico-precios'].refreshRows()
      }
    }


  },
};
</script>

<style scoped>
.carga-data {
  margin-top: 4%;
}
</style>

